<template>
  <div class="about-us-container">
    <div class="about-us-content">
      <div class="about-us-text">
        <h2 class="section-title">
          Hi there! You just found Guided Valley? Let us introduce ourselves then!
        </h2>
        <img
          class="photo"
          src="https://blogify-images.s3.eu-west-3.amazonaws.com/main-banner/about.webp"
          loading="lazy"
          alt="Photo of Joan and Julieta"
        >
        <p class="paragraph">
          🌍🌟 Welcome to Guided Valley – your travel companion where wanderlust meets practicality, and adventure is just a click away. Meet Lulu and Denise, the dynamic duo behind this digital haven, dedicated to simplifying your travel experience while igniting your passion for exploration. Our journey began amidst the vibrant energy of Barcelona, where dreams take flight and possibilities are endless.
        </p>
        <p class="paragraph">
          🌴🌞 In the heart of Barcelona, between the whispers of the Mediterranean and the golden hues of sun-drenched skies, fate brought us together. Lulu, armed with a camera and a limitless adventurous spirit, captured the essence of the city he dreamed of living in since he was young through her lens. For her part, Denise, a wordsmith with a penchant for storytelling, found inspiration in the enchanting beauty of the city.
        </p>
        <p class="paragraph">
          📷✍️ Fate intervened, weaving our shared love for wanderlust into an unbreakable bond. Fueled by our desire to make travel accessible and unforgettable, we embarked on a journey beyond borders, leaving behind familiar shores to embrace the world's wonders.
        </p>
        <p class="paragraph">
          🌎✈️  Our travels have taken us to the hidden gems of this planet, from the enchanting alleys of European towns to the vibrant markets of Ecuador, from the sun-soaked beaches of the Caribbean to the snow-capped peaks of the Andes. Every step we take, every story we share, is a testament to the magic of discovery and the beauty of connection.
        </p>
        <p class="paragraph">
          🗺️📖 Our mission is clear: to be your trusted guides in the realm of travel. Through curated recommendations, insider tips, and firsthand experiences, we aim to make your journey seamless and unforgettable. Whether you seek cozy accommodations, thrilling activities, or cultural immersion, we've got you covered.
        </p>
        <p class="paragraph">
          🌈🔗 But beyond being mere travelers  we are storytellers at heart. Our blog serves as a canvas where practical advice meets immersive narratives, offering a window into the diverse tapestry of our world. From bustling cityscapes to tranquil landscapes, each post invites you to embark on a journey of discovery.
        </p>
        <p class="paragraph">
          💌🌟 As we continue to navigate the globe hand in hand, we invite you to join us. Let our insights inspire your next voyage, and let our experiences fuel your wanderlust. Whether you're an intrepid explorer or an armchair traveler, GuidedValley is your compass when it comes to travel.
        </p>
        <p class="paragraph">
          🌍🌟 Thank you for being a part of our journey. Together, let's celebrate the magic of travel and the joy of discovery. Welcome to our loved project, where every post is a gateway to new experiences and unforgettable memories.
        </p>
        <p class="paragraph">
          🌟🗺️ With love and wanderlust,
        </p>
        <p class="paragraph">
          <br> <br> Lulu and Denise.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  created() {
    this.resetMetaData()
  },
  methods: {
    resetMetaData() {
      const metaDescription = document.querySelector('head meta[name="description"]')
      const metaTitle = document.querySelector('head meta[property="title"]')
      const metaKeywords = document.querySelector('head meta[name="keywords"]')
      const defaultKeywords = 'travel, adventure, journey, epic stories, tips'
      const title = document.querySelector('head title')
      const linkCanonical = document.querySelector('head link[rel="canonical"]')

      metaDescription.setAttribute('content', 'Join our journey! Join our trip! Discover the best tips and what you can\'t miss on your next trip. Let us make your adventures unforgettable - explore with us today!')
      metaTitle.setAttribute('content', 'Guided Valley: About Us')
      title.textContent = 'Guided Valley: About Us'
      metaKeywords.setAttribute('content', defaultKeywords)
      linkCanonical.setAttribute('href', 'https://guidedvalley.com/about')
    }
  },
}
</script>

<style scoped>

.about-us-container {
display: flex;
justify-content: center;
align-items: center;
}

.about-us-content {
max-width: 1400px;
padding: 50px;
height: 100%;
text-align: left;
background-color: rgba(255, 255, 255, 0.9);
border-radius: 10px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.about-us-text {
  font-size: 22px;
  line-height: 1.6;
  margin-top: 50px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
}

.paragraph {
  margin-bottom: 20px;
}

.section-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
}
.photo {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-bottom: 30px;
}

.container-image {
  position: relative;
  text-align: center;
  color: white;
}
</style>

