<template>
  <header> 
    <div class="app">
      <Navigation />
      <div class="container-image">
        <div class="hero" />
        <h1 class="hero-banner">
          PLAN, TRAVEL & ENJOY
        </h1>
        <h2 class="sub-banner">
          Perfect Guide for Stress-Free Travel Planning
        </h2>
      </div>
      <router-view />
      <Footer />
    </div>
  </header>
</template>

<script>
import Navigation from '@/components/navigation/Navigation.vue'
import Footer from '@/components/footer/Footer.vue'


export default {
  el: '#app',
  name: 'App',
  components: { Footer, Navigation },
  data() {      
    return {
    }
  },
  created() {

    //TODO: Don't know if works, check it out
    this.$router.beforeEach((to, _from, next) => {
      if (to.meta.statusCode) {
        document.status = to.meta.statusCode // Set the status code
      }
      next()
    })
  },
  methods: {
    onAcceptAllCookies() {
      // Create and append the script element for Google Tag Manager
      const script = document.createElement('script')
      script.setAttribute('nonce', 'sha256-5568a1045d67a2337fde34faf6523141d5c49b6099960d3e7cff86bc4ae790b0')
      script.innerHTML = `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-M2HPV8DV');
  `
      document.head.appendChild(script)

      // Create and append the noscript iframe for Google Tag Manager
      const noscriptIframe = document.createElement('iframe')
      noscriptIframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-M2HPV8DV')
      noscriptIframe.setAttribute('height', '0')
      noscriptIframe.setAttribute('width', '0')
      noscriptIframe.setAttribute('style', 'display:none;visibility:hidden')
      document.body.appendChild(noscriptIframe)
    },
    onSavePreferences(_params) {
      // Create and append the script element for Google Tag Manager
      const script = document.createElement('script')
      script.setAttribute('nonce', 'sha256-5568a1045d67a2337fde34faf6523141d5c49b6099960d3e7cff86bc4ae790b0')
      script.innerHTML = `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-M2HPV8DV');
  `

      document.head.appendChild(script)

      // Create and append the noscript iframe for Google Tag Manager
      const noscriptIframe = document.createElement('iframe')
      noscriptIframe.setAttribute('src', 'https://www.googletagmanager.com/ns.html?id=GTM-M2HPV8DV')
      noscriptIframe.setAttribute('height', '0')
      noscriptIframe.setAttribute('width', '0')
      noscriptIframe.setAttribute('style', 'display:none;visibility:hidden')
      document.body.appendChild(noscriptIframe)
    },
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
}

.app {
  min-height: 100vh;
  position: relative;
  background-color: #ffffff;
}
.container {
  max-width: 1140px;
  margin: 0 auto;
}

.cookie-comply {
  border: 1px solid black;
  position: sticky; 
  background-color: rgba(50,50,58,1);
  color: #ffffff;
  bottom: 0;
  border-radius: 0;
}

.cookie-comply__modal {
  position: fixed;
}

.cookie-comply__modal-inner {
  padding: 30px;
  width: 600px;
  @media (max-width: 1000px) {
    width: 100%;
  }
}

.cookie-comply__modal-header h3 {
  padding-left: 20px;
}

.cookie-comply__modal-content {
  margin-top: 25px;
  overflow: hidden;
}

.cookie-comply__modal-content > div {
  margin-bottom: 25px;
}

.cookie-comply__button {
  margin-top: 10px;
}

.container-image {
  position: relative;
  text-align: center;
  color: white;
}

.hero{
  background-image: url("https://blogify-images.s3.eu-west-3.amazonaws.com/main-banner/david-marcu-78A265wPiO4-unsplash.webp");
  height: 400px;

  /* Create the parallax scrolling effect background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-banner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font: bold 55px "Oswald", Sans-serif;
  color: #ffffff;
  padding-top: 30px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 750px) {
    font-size: 30px;
  }
  /* Styles for screens up to 1000px wide */
  @media (min-width: 750.1px) and (max-width: 1250px) {
    font-size: 35px;
  }
  /* Styles for screens between 1201px and 2000px wide */
  @media (min-width: 1251px) and (max-width: 1900px) {
    font-size: 45px;
  }
}

.sub-banner {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font: bold 30px Helvetica;
  color: #ffffff;
  padding-top: 30px;
  /* Styles for screens up to 750px wide */
  @media (max-width: 750px) {
  font-size: 13px;
  padding-top: 5px;
  }
   /* Styles for screens between 750px and 1250px wide  */
   @media (min-width: 750.1px) and (max-width: 1250px) {
    width: 65%;
    font-size: 15px;
  }
  /* Styles for screens between 1201px and 1900px wide */
  @media (min-width: 1250.1px) and (max-width: 1900px) {
    font-size: 23px;
  }
}
</style>
