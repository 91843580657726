<template>
  <div class="container">
    <h2 class="banner">
      TOP WORLDWIDE CITY DESTINATIONS
    </h2>
    <div class="grid">
      <div
        v-for="city in cities"
        :key="city.id"
      >
        <CityCard
          :city-name="city.name"
          :city-id="city.id"
          :country-name="city.country.name"
          :photo-url="city.photo_urls[0]"
        />
      </div>
    </div>
    <h2 class="banner">
      TOP WORLDWIDE COUNTRY DESTINATIONS
    </h2>
    <div class="grid">
      <div
        v-for="country in countries"
        :key="country.id"
      >
        <CountryCard
          :country="country.name"
          :photo-url="country.photo_urls[0]"
        />
      </div>
    </div>
  </div>
</template>

<script>

import CountryCard from '@/components/destination/CountryCard.vue'
import CityCard from '@/components/destination/CityCard.vue'
import CountryResource from '../../api/country.service'
import CityResource from '../../api/city.service'

export default {
  name: 'Home',
  components: {CountryCard, CityCard},
  data() {      
    return {
      countries: [],
      cities: [],
    }
  },
  created() {
    this.resetMetaData()

    // Request data when the component is created.
    CountryResource.getTopCountries().then(response => {
      this.countries = response.data
    })
      .catch(e => {
        console.log(e)
      })
    CityResource.getTopCities().then(response => {
      this.cities = response.data
    })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {
    resetMetaData() {
      const metaDescription = document.querySelector('head meta[name="description"]')
      const metaTitle = document.querySelector('head meta[property="title"]')
      const metaKeywords = document.querySelector('head meta[name="keywords"]')
      const defaultKeywords = 'travel, adventure, journey, epic stories, tips'
      const title = document.querySelector('head title')
      const linkCanonical = document.querySelector('head link[rel="canonical"]')

      metaDescription.setAttribute('content', 'Join our journey! Discover epic travel stories and expert tips to conquer the world. Let\'s make your adventures unforgettable. Explore with us today!')
      metaTitle.setAttribute('content', 'Guided Valley - Perfect Guide for Stress-Free Travel Planning')
      title.textContent = 'Guided Valley - Perfect Guide for Stress-Free Travel Planning'
      metaKeywords.setAttribute('content', defaultKeywords)
      linkCanonical.setAttribute('href', 'https://guidedvalley.com')
    }
  },
}
</script>

<style lang="scss" scoped>
.container{
  margin-right: auto;
  margin-left: auto;
  max-width: fit-content;
  margin-bottom: 52px;
}

.banner {
  margin: auto;
  width: 100%;
  border-bottom: 1px solid black;
  padding: 20px;
  font-size: 25px;
  padding-top: 50px;
  text-align: center;
}
.grid {
  margin-top: 32px;
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  /* Styles for screens between 1201px and 2000px wide */
  @media (min-width: 1001px) and (max-width: 2000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
