import HTTP from './http-common'

/*
 * Service should be singleton,
 * hence we could declare a simple object literal.
 */
let CountryResource = {
  getTopCountries() { 
    return HTTP.get('/top-countries')
  },
  getCountryByName(countryName) { 
    return HTTP.get('/countries/' + countryName)
  },
  getAllCountries() { 
    return HTTP.get('/all-countries')
  },
}

export default CountryResource
