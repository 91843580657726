<template>
  <div class="container">
    <!-- <h1 class="banner">
      TOP DESTINATIONS
    </h1> -->
    
    <div
      v-for="continent in continents"
      :key="continent.id"
      class="continent-section"
    >
      <h2 class="continent-name">
        {{ continent.name.toUpperCase() }}
      </h2>
      <div 
        class="grid"
      >
        <div
          v-for="country in getAllCountriesByContinentId(continent.id)"
          :key="country.id"
        >
          <CountryCard
            :country="country.name"
            :description="country.description"
            :photo-url="country.photo_urls[0]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryCard from '@/components/destination/CountryCard.vue'
import CountryResource from '../../api/country.service'
import continents from '@/configuration/country_parents.json'

export default {
  name: 'Destinations',
  components: { CountryCard },
  data() {
    return {
      countries: [],
      continents: continents,
    }
  },
  created() {
    this.resetMetaData()

    // Request data when the component is created.
    CountryResource.getAllCountries().then(response => {
      this.countries = response.data
    })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {
    resetMetaData() {
      const metaDescription = document.querySelector('head meta[name="description"]')
      const metaTitle = document.querySelector('head meta[property="title"]')
      const metaKeywords = document.querySelector('head meta[name="keywords"]')
      const defaultKeywords = 'travel, adventure, journey, epic stories, tips'
      const title = document.querySelector('head title')
      const linkCanonical = document.querySelector('head link[rel="canonical"]')
      const currentYear = new Date().getFullYear()

      metaDescription.setAttribute('content', 'Discover the top destinations this ' + currentYear + ', epic travel stories and expert tips to conquer the world. Let\'s make your adventures unforgettable. Explore with us today!')
      metaTitle.setAttribute('content', 'Guided Valley: Visit Top Destinations in ' + currentYear)
      title.textContent = 'Guided Valley: Visit Top Destinations in ' + currentYear
      metaKeywords.setAttribute('content', defaultKeywords)
      linkCanonical.setAttribute('href', 'https://guidedvalley.com/destinations')
    },
    getAllCountriesByContinentId(continentId) {
      return this.countries.filter((country) => country.continent_id === continentId)
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  margin-right: auto;
  margin-left: auto;
  max-width: fit-content;
  margin-bottom: 52px;
}

.banner {
  text-align: center;
  width: 100%;
  font-size: 30px;
  margin-top: 4%;
  padding-bottom: 10px;
  font-family: "Oswald", Sans-serif;
}

.continent-name{
  text-align: center;
  width: 100%;
  font-size: 30px;
  margin-top: 4%;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
  font-family: "Oswald", Sans-serif;
  font-size: 45px;
  @media (max-width: 750px) {
    font-size: 30px;
    padding-top: 20px;
  }
  /* Styles for screens up to 1000px wide */
  @media (min-width: 750.1px) and (max-width: 1250px) {
    font-size: 35px;
  }
  /* Styles for screens between 1201px and 2000px wide */
  @media (min-width: 1251px) and (max-width: 1900px) {
    font-size: 40px;
  }
}
* {
  // make it sexy
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  letter-spacing: -0.25px;
}

strong {
  color: #292929;
}

.grid {
  margin-top: 25px;
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  /* Styles for screens between 1201px and 2000px wide */
  @media (min-width: 1001px) and (max-width: 2000px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
