<template>
  <header>
    <nav> 
      <a
        href="/"
        class="logo-container"
      >
        <img
          class="logo-img"
          :src="logo"
          loading="lazy"
          alt="Logo of Guided Valley"
        >
      </a>

      <input
        id="menu-toggle"
        type="checkbox"
      >
      <label
        class="menu-button-container"
        for="menu-toggle"
      >
        <div
          id="burger-menu" 
          class="menu-button" 
        />
      </label>

      <ul class="nav-links">
        <!-- There are currently two search input as they behave dynamically based on css rules -->
        <!-- <li> 
          <SearchInput v-model="searchVal" />
        </li> -->
        <li class="menu-element">
          <router-link
            class="link"
            :to="{ name: 'Home' }"
            @click="closeMenu"
          >
            Home
          </router-link>
        </li>

        <li class="menu-element">
          <router-link
            class="link"
            :to="{ name: 'Destinations' }"
            @click="closeMenu"
          >
            Destinations
          </router-link>
        </li>
        <li class="menu-element">
          <router-link
            class="link"
            :to="{ name: 'About' }"
            @click="closeMenu"
          >
            About
          </router-link>
        </li>
        <li class="search-bar"> 
          <SearchInput
            v-model="searchVal"
            type="text"
            placeholder="Search your destination"
            pattern="[A-Za-z]+"
            maxlength="23"
            :search-icon="true"
            :shortcut-icon="true"
            :clean-icon="true"
            :clean-on-esc="true"
            :select-on-focus="true" 
            :shortcut-listener-enabled="true"
            shortcut-key="/"
            @update:modelValue="throttledSearchCities"
          />
          <!-- Display search results -->
          <div
            v-if="searchResultsVisible"
            class="searchResults"
          >
            <ul>
              <a
                v-for="result in searchResults"
                :key="result.id"
                :href="result.page.url"
                class="element-wrapper"
              >
                <li
                  class="result-item"
                >{{ result.name }}</li>
              </a>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import SearchResource from '../../api/search.service'
import { ref } from 'vue'
import SearchInput from 'vue-search-input'
import { throttle } from 'lodash'


export default {
  el: '#navigation',
  name: 'Navigation',
  components: { SearchInput },
  data() {
    return {
      scrollNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      logo: require('@/assets/logo.png'), // Path to your SVG logo
      searchResults: [],
      searchResultsVisible: false,
      searchVal: '',
      inputError: '',
    }
  },
  watch: {
    searchVal(newValue) {
      this.searchResultsVisible = newValue.trim() !== ''
    }
  },
  created() {
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
  },
  
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  setup() {
    const searchVal = ref('')
    const searchResults = ref([])
    
    const searchCities = async () => {
      
      if (searchVal.value.trim() !== '') {
        try {
          const response = await SearchResource.search(searchVal.value)
          searchResults.value = response.data
        } catch (error) {
          console.error('Error searching cities:', error)
        }
      } else {
        // Clear searchResults or perform any other desired action when searchVal is empty
        searchResults.value = []
      }
    }

    const throttledSearchCities = throttle(searchCities, 1000) // Adjust the delay (in milliseconds) as needed
    return {
      throttledSearchCities,
      searchVal,
      searchResults
    }
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav
    },
    updateScroll() {
      this.scrollNav = window.scrollY > 50
    },
    checkScreen() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 750) {
        this.mobile = true
        return
      }
      this.mobile = false
      this.mobileNav = false
    },
    closeMenu() {
      document.getElementById('menu-toggle').checked = false
      console.log('close menu ')
    },
  }  
}
</script>

<style lang="scss" scoped>
@import '../../../node_modules/vue-search-input/dist/styles.css';

header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #111111;
  height: 85px;
  position: relative;
  z-index: 99;

  nav {
    display: flex;
    width: 100%;
    transition: 0.5s ease all;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    height: 50px;
    padding: 1em;


    ul,
    .link {
      font-weight: 500;
      color: #111111;;
      list-style: none;
      text-decoration: none;
    }

    li {
      padding: 16px;
      margin-left: 16px;
      position: static;
      text-align: center;
    }

    .link {
      transition: 0.5s ease all;
      color: #FFFFFF;
      font-size: 22px;
      // Adding hover effect, so border color needs to be transparent
      border-bottom: 1px solid transparent;
  
      &:hover {
        color: deepskyblue;
        border-color: deepskyblue;
      }

       /* Styles for screens up to 1000px wide */
        @media (max-width: 1000px) {
          font-size: 14px;
      }
    }
    .nav-links {
      display: flex;
      justify-content: space-around;
      text-align: right;
    }
  }
}

h4{
  color: rgb(238, 238, 238);
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 20px;
}

.logo-container {
  align-self: center;
  margin-left: 65px;
  @media (max-width: 1250px) {
  margin-left: 15px; 
  }
}
.logo-img {
  height: 55px;
  width: 210px;
  /* Styles for screens up to 750px wide */
  @media (max-width: 750px) {
    height: 27px;
    width: 105px;
  }
  /* Styles for screens between 750px and 1250px wide  */
  @media (min-width: 750.1px) and (max-width: 1250px) {
    height: 33px;
    width: 126px;
}
  /* Styles for screens between 1251px and 1900px wide */
  @media (min-width: 1250.1px) and (max-width: 1900px) {
    height: 44px;
    width: 168px;
  }
}
.menu-element {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 0 10px;
}

.nav-links {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-links > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

// .nav-links > li:first-child {
//    visibility: hidden;
// }

@media (max-width: 1600px) {

  li {
    padding-left: 20px;
  }
  .menu-button-container {
    display: flex;
  }
  .nav-links {
    position: absolute;
    top: 35px;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .nav-links li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .nav-links li {
    border: 1px solid #111111;
    height: 3em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    justify-content: center;
  }

  .nav-links > li {
    display: flex;
    justify-content: left;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #111111;
  }
  .nav-links > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }

  .nav-links > li:first-child {
    visibility: visible;
  }

  .nav-links > li:last-child {
    visibility: hidden;
  }
}

  .searchResults{
    position: absolute;
    display: block;
    background-color: black;
    width: 254.24px;
    overflow: auto;
    border: 1.5px solid white;
    border-top: 1px black;
    box-shadow: 13px 14px 5px 0px rgba(0,0,0,0.22);
    border-radius: 0px 0px 20px 20px;

  }
  .result-item {
    color: white;
    font-family: inherit;
    text-align: center;
    margin: 0;
    font-size: 20px;

    &:hover {
      color: deepskyblue;
      text-decoration: underline;
      background-color: rgb(0, 41, 54);
    }
  }
  .element-wrapper{
    text-decoration: none;
  }

</style>
