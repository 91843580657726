<template>
  <div
    class="destinations"
    @click="isOpen = !isOpen"
  >
    <router-link to>
      {{ destinations }}
      <svg
        viewBox="0 0 1030 638"
        width="10"
      >  
        <path
          d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z"
          fill="#FFF"
        />
      </svg>

      <transition
        apear
        name="fade"
      >
        <div
          v-if="isOpen"
          class="sub-menu"
        >
          <div class="menu-item">
            <router-link to="/destinations/asia">
              Asia
            </router-link>
            <router-link to="/destinations/africa">
              Africa
            </router-link>
            <router-link to="/destinations/northamerica">
              North America
            </router-link>
            <router-link to="/destinations/southamerica">
              South America
            </router-link>
            <router-link to="/destinations/antarctica">
              Antarctica
            </router-link>
            <router-link to="/destinations/europe">
              Europe
            </router-link>
            <router-link to="/destinations/australia">
              Australia
            </router-link> 
          </div>
        </div>
      </transition>
    </router-link>
  </div>
</template>
  
<script>

  
export default {
  name: 'Destinations',
  data() {
    return {
      isOpen: false,
    }
  },
  async fetch() {},
}
</script>
  
<style lang="scss" scoped>
.destinations {
  width: 100%;
  height: 100%;
}

.sub-menu {
  padding-left: 50px;
}

strong {
  color: #292929;
}
nav .menu-item svg {
    width: 10px;
    margin-left: 10px;
}
nav .menu-item .sub-menu {
  position: absolute;
  background-color: #222;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  border-radius: 0px 0px 16px 16px;
}


</style>
