import { createRouter, createWebHistory } from 'vue-router'
import Destinations from '@/views/destinations/Destinations.vue'
import CountryDetails from '@/views/country/CountryDetails.vue'
import CityDetails from '@/views/city/CityDetails.vue'
import ArticleDetails from '@/views/article/ArticleDetails.vue'
import Home from '@/views/home/Home.vue'
import NotFound from '@/components/navigation/NotFound.vue'
import Continents from '@/components/dropwdown/Continents.vue'
import About from '@/views/about/About.vue'
import Privacy from '@/views/privacy/Privacy.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/destinations',
    name: 'Destinations',
    component: Destinations,
  },
  {
    path: '/countries/:country',
    name: 'CountryDetails',
    props: true,
    component: CountryDetails,
  },
  {
    path: '/countries/:country/cities/:city',
    name: 'CityDetails',
    props: true,
    component: CityDetails,
  },
  {
    path: '/countries/:country/cities/:city/articles/:article',
    name: 'ArticleDetails',
    component: ArticleDetails,
  },
  {
    path: '/continents',
    name: 'Continents',
    component: Continents,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  //{ path: '/:catchAll(.*)', component: NotFound },
  {
    path: '/:catchAll(.*)', // Matches any route that hasn't been matched so far
    name: 'NotFound',
    component: NotFound,
  },
  /*
   * {
   *   path: '/404',
   *   redirect: { name: 'NotFound' }, // Redirect to the 'NotFound' route
   * },
   */
]

const router = createRouter({
  // TODO: Take a look here if history is needed
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  // Scroll to top before navigating to a new route
  window.scrollTo(0, 0)
  next()
})


// router.beforeEach((to, from, next) => {
//   if (to.matched.length === 0) {
//   // No route matched, set the HTTP status code to 404
//     return next({ path: '/404', status: 404 })
//   }
//   next()
// })

export default router
