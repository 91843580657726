<template>
  <div class="item">
    <section>
      <img
        class="photo"
        :src="photoUrl"
        :alt="buildAltTag()"
        loading="lazy"
        @click="forwardToCountryDetails"
      >
      <h2
        class="name"
        @click="forwardToCountryDetails"
      >
        {{ country.toUpperCase() }}
      </h2>
      <!-- <p
        class="description"
        @click="forwardToCountryDetails"
      >
        {{ description }}
      </p> -->
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'CountryCard',
  components: {},
  props: {
    country: {
      type: String,
      required: true,
    },
    photoUrl: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  methods: {
    forwardToCountryDetails() {
      this.$router.push({
        name: 'CountryDetails',
        params: { country: this.country.toLocaleLowerCase().replace(/\s+/g, '-') },
      })
    },
    buildAltTag() {
      return `A breathtaking view of ${this.country}`
    },
  },
}
</script>

<style lang="scss" scoped>
.location-card {
  display: flex;
}

.item {
  display: grid;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: all 300ms;
  height: 440px;
  width: 100%;
  background-color: #fbfbfb;
  border: 1px solid rgb(209 206 206 / 33%);
}

.item:hover {
  transform: translateY(-2px);
  box-shadow: 3px 3px 5px 10px  rgba(0, 0, 0, 0.22),  -5px -5px 25px 10px  rgba(0, 0, 0, 0.22)
}

.name {
  font-size: 25px;
  font-weight: 700;
  padding-left: 5px;
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
}

.photo {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.description {
  padding: 10px;
  height: 200px;
  width: 100%;
  text-align: initial;
  line-height: 1.7;
  font-size: 18px;
  word-break: break-word;
}
</style>
