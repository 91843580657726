<template>
  <div
    class="vww__widget"
    :style="{ color: textColor }"
  >
    <slot name="header">
      <div
        v-if="!hideHeader"
        class="vww__header"
        :style="{ borderColor: barColor }"
      >
        <span class="vww__title">
          <slot name="title">Weather</slot>
        </span>
      </div>
    </slot>
  
    <div class="vww__content">
      <div
        v-if="loading"
        class="vww__loading"
      >
        <slot name="loading">
          <skycon
            condition="partly-cloudy-day"
            :color="textColor"
            :paused="disableAnimation"
          />
          <span class="vww__title">Loading...</span>
        </slot>
      </div>
  
      <div
        v-else-if="error || !weather || !currently || !daily"
        class="vww__error"
      >
        <slot name="error">
          <skycon
            condition="rain"
            :color="textColor"
            :paused="disableAnimation"
          />
          <span class="vww__title">{{ error || "Something went wrong!" }}</span>
        </slot>
      </div>
  
      <template v-else>
        <div class="vww__currently">
          <div>
            <skycon
              :condition="currently.icon"
              size="80"
              :color="textColor"
              :paused="disableAnimation"
            />
            <div class="vww__temp">
              {{ Math.round(currently.temperature) }}&deg;
              <div v-if="isDownward">
                <svg
                  viewBox="0 0 306 306"
                  width="24"
                  height="24"
                >
                  <polygon
                    points="270.3,58.65 153,175.95 35.7,58.65 0,94.35 153,247.35 306,94.35"
                    :style="{ fill: textColor }"
                  />
                </svg>
              </div>
              <div v-else>
                <svg
                  viewBox="0 0 306 306"
                  width="24"
                  height="24"
                >
                  <polygon
                    points="35.7,247.35 153,130.05 270.3,247.35 306,211.65 153,58.65 0,211.65"
                    :style="{ fill: textColor }"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="vww__title">
            {{ currently.summary }}
          </div>
          <div class="vww__wind">
            Wind: {{ Math.round(currently.windSpeed) }} mph ({{ windBearing }})
          </div>
        </div>
  
        <div class="vww__daily">
          <div
            v-for="day in daily"
            :key="day.time"
            class="vww__day"
          >
            <span>{{ day.weekName }}</span>
            <span>
              <skycon
                style="display: block"
                :condition="day.icon"
                size="26"
                :color="textColor"
                :paused="disableAnimation"
              />
            </span>
            <div class="vww__day-bar">
              <div :style="{ height: `${day.top}%` }">
                <span>{{ Math.round(day.temperatureMax) }}&deg;</span>
              </div>
              <div
                :style="{
                  borderRadius: '10px',
                  background: barColor,
                  height: `${day.height}%`,
                }"
              >
                  &nbsp;
              </div>
              <div :style="{ height: `${day.bottom}%` }">
                <span>{{ Math.round(day.temperatureMin) }}&deg;</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
  
<script>
import Utils from './utils'
import { ref, nextTick } from 'vue'
import Skycon from 'vue-skycons'

export default {
  name: 'VueWeatherWidget',

  components: {
    Skycon,
  },

  props: {
    // Pass true to use DarkSky API, otherwise it will use OpenWeatherMap API
    useDarkSkyApi: {
      type: Boolean,
      default: false,
    },

    // Your Dark Sky / OpenWeatherMap secret key
    apiKey: {
      type: String,
      required: true,
    },

    /*
     * // Address to lookup location.
     * address: {
     *   type: String,
     * },
     */

    /*
     * The latitude of a location (in decimal degrees).
     * Positive is north, negative is south.
     */
    latitude: {
      type: String,
    },

    /*
     * The longitude of a location (in decimal degrees).
     * Positive is east, negative is west.
     */
    longitude: {
      type: String,
    },

    /*
     * Return summary properties in the desired language.
     * For list of supported languages, visit https://darksky.net/dev/docs/forecast
     */
    language: {
      type: String,
      default: 'en',
    },

    /*
     * Return weather conditions in the requested units.
     * For list of supported units, visit https://darksky.net/dev/docs/forecast
     */
    units: {
      type: String,
      default: 'us',
    },

    // Controls whether to show or hide the title bar.
    hideHeader: {
      type: Boolean,
      default: false,
    },

    // Auto update interval in milliseconds
    updateInterval: {
      type: Number,
    },

    // Use static skycons
    disableAnimation: {
      type: Boolean,
      default: false,
    },

    // Color of the Temparature bar. Default: '#444'
    barColor: {
      type: String,
      default: '#444',
    },

    // Color of the text. Default: '#333'
    textColor: {
      type: String,
      default: '#333',
    },

    /*
     * // Your positionstack api key for geocoding
     * positionstackApi: {
     *   type: String,
     *   default: "7f9c71310f410847fceb9537a83f3882",
     * },
     */

    // Your ipregistry key to get location from ip address
    ipregistryKey: {
      type: String,
      default: 'f8n4kqe8pv4kii',
    },
  },

  data() {
    return {
      loading: ref(true),
      weather: ref(null),
      error: ref(null),
      timeout: null,
    }
  },

  watch: {
    apiKey: 'hydrate',
    latitude: 'hydrate',
    longitude: 'hydrate',
    language: 'hydrate',
    units: 'hydrate',
    updateInterval: 'hydrate',
  },

  mounted() {
    this.hydrate()
  },

  beforeUnmount() {
    clearTimeout(this.timeout)
  },

  computed: {
    currently() {
      return this.weather ? this.weather.currently : null
    },
    // eslint-disable-next-line vue/return-in-computed-property
    isDownward() {
      const hourly = this.weather ? this.weather.hourly.data : []
      const time = new Date().getTime() / 1e3
      for (let i = 0; i < hourly.length; i++) {
        if (hourly[i].time <= time) continue
        return hourly[i].temperature < this.currently.temperature
      }
    },
    windBearing() {
      const t = Math.round(this.currently.windBearing / 45)
      return ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'][t]
    },
    daily() {
      const forecasts = []
      let globalMaxTemp = -Infinity
      let globalMinTemp = Infinity

      const tomorrow = new Date(new Date().toDateString())
      const today = tomorrow.getTime() / 1e3 + 24 * 3600 - 1

      const daily = this.weather ? this.weather.daily.data : []
      for (let i = 0; i < daily.length; i++) {
        const day = daily[i]
        if (day.temperatureMax > globalMaxTemp) {
          globalMaxTemp = day.temperatureMax
        }
        if (day.temperatureMin < globalMinTemp) {
          globalMinTemp = day.temperatureMin
        }
        forecasts.push({ ...day })
      }

      const tempRange = globalMaxTemp - globalMinTemp
      for (let i = 0; i < forecasts.length; ++i) {
        const day = forecasts[i]
        if (day.time <= today) {
          day.weekName = 'Today'
        } else {
          day.weekName = new Date(day.time * 1000).toLocaleDateString(this.language, {
            weekday: 'short',
          })
        }
        const max = day.temperatureMax
        const min = day.temperatureMin
        day.height = Math.round((100 * (max - min)) / tempRange)
        day.top = Math.round((100 * (globalMaxTemp - max)) / tempRange)
        day.bottom = 100 - (day.top + day.height)
      }
      return forecasts
    },
  },

  methods: {
    hydrate(setLoading = true) {
      this.loading = setLoading
      nextTick()
        .then(this.processLocation)
        .then(this.loadWeather)
        .then(() => {
          this.error = null
        })
        .catch((err) => {
          this.error = '' + err
        })
        .finally(() => {
          this.loading = false
          this.autoupdate()
        })
    },

    processLocation() {
      if (!this.latitude || !this.longitude) {
        throw new Error('VueWeatherWidget: Latitude or longitude is required')
      } else {
        /*
         * Uncomment and adapt as needed
         * return Utils.reverseGeocode(this.positionstackApi, this.latitude, this.longitude).then((data) => {
         *   // Update location
         * });
         */
      }
    },

    async loadWeather() {
      const fetchWeatherMethod = this.useDarkSkyApi ? Utils.fetchWeather : Utils.fetchOWMWeather
      const data = await fetchWeatherMethod({
        apiKey: this.apiKey,
        lat: this.latitude,
        lng: this.longitude,
        units: this.units,
        language: this.language,
      })
      this.weather = data
    },

    autoupdate() {
      clearTimeout(this.timeout)
      const time = Number(this.updateInterval)
      if (!time || time < 10 || this.destroyed) {
        return
      }
      this.timeout = setTimeout(() => this.hydrate(false), time)
    },
  },
}

</script>
  
<style>

.vww__widget {
    width: 100%;
    min-width: 250px;
    max-width: 800px;
  }
  
  .vww__header {
    position: relative;
    padding: 10px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
  
  .vww__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
  }
  
  .vww__content {
    min-height: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    overflow: hidden;
  }

  @media screen and (min-width: 600px) {
    .vww__content {
        min-height: 150px;
        height: 300px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        overflow: hidden;
    }
  }
  
  .vww__loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vww__loading span {
    display: block;
    margin-left: 10px;
  }
  
  .vww__error {
    width: 100%;
    text-align: center;
  }
  .vww__error span {
    display: block;
    padding: 10px;
  }
  
  .vww__currently {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .vww__currently > div {
    display: flex;
    align-items: center;
  }
  
  .vww__currently .vww__title {
    margin-top: 10px;
  }
  
  .vww__temp {
    padding: 0 10px;
    font-size: 50px;
    font-weight: 700;
    line-height: 0.65em;
  }
  
  .vww__temp > div {
    display: block;
    text-align: center;
    padding-right: 10px;
  }
  
  .vww__wind {
    font-size: 14px;
  }
  
  .vww__daily {
    margin-top: 30px;
    display: none;
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;
  }
  
  @media screen and (min-width: 600px) {
    .vww__currently {
      width: 300px
    }
    .vww__daily {
      display: block;
      height: 100%;
      width: calc(100% - 300px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow-x: auto;
    }
  }
  
  .vww__day {
    height: 100%;
    text-align: start;
    position: relative;
    min-width: 40px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  
  .vww__day > span {
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .vww__day-bar {
    margin-top: 20px;
    width: 30px;
    height: calc(100% - 100px);
  }
  
  .vww__day-bar div {
    margin: 0 5px;
    display: flex;
  }
  
  .vww__day-bar div:first-child {
    align-items: flex-end;
  }
  
  .vww__day-bar div:last-child {
    align-items: flex-start;
  }
  
  .vww__day-bar span {
    display: block;
    font-size: 12px;
  }
</style>
