import HTTP from './http-common'

/*
 * Service should be singleton,
 * hence we could declare a simple object literal.
 */
let CityResource = {
  getTopCities() { 
    return HTTP.get('/top-cities')
  },
  getCityByCountryAndCity(country, city) { 
    return HTTP.get('/countries/' + country + '/cities/' + city)
  },
}

export default CityResource
