<template>
  <div class="country-details-container">
    <div class="table-container">
      <div class="column-first" />
      <div class="column-second">
        <div class="bread-crumb">
          <router-link
            :to="{ name: 'Home'}"
          >
            Home
          </router-link>
          >
          <span> 
            {{ getNameCapitalized(country) }} 
          </span>
        </div>
        <h1 class="countryName">
          {{ getNameCapitalized(country).toUpperCase() }} TRAVEL GUIDE
        </h1>

        <p class="paragraph-container">
          {{ description }}
        </p>

        <div
          v-if="showTiqetsWidget"
          class="tiqets-widget-container"
        >
          <h2>
            Top activities we recommend:
          </h2>
          <!-- <div
            data-tiqets-widget="discovery"
            data-cards-layout="responsive"
            data-content-type="product"
            data-item_count="5"
            data-destination-type="country"
            :data-destination-id="tiqetsId"
            data-language="en"
            data-partner="guided_valley"
            data-tq-campaign="october2023"
          /> -->
          <div data-gyg-widget="auto" />
        </div>

        <img
          class="photo-paragraph"
          :src="photo"
          loading="lazy"
          :alt="buildAltTag()"
        >

        <div class="paragraph-section">
          <h2>{{ getNameCapitalized(country) }} Top Cities</h2>

          <div class="grid">
            <div 
              v-for="city in topCities"
              :key="city.id"
              class="city_container"
            >
              <CityCard
                class="item"
                :city-name="city.name"
                :city-id="city.id"
                :photo-url="city.photo_urls[0]"
                :country-name="country"
                :name-size="city.name.length > 11 ? '20px' : '25px'"
              />
            </div>
          </div>
        </div>
          

        <div class="paragraph-section">
          <h2>Public transport</h2>
          <p class="paragraph-container">
            {{ transport }}
          </p>
        </div>
          

        <div
          v-if="showTiqetsWidget"
          class="tiqets-widget-container"
        >
          <h2> Discover Exclusive Special Deal Packages in {{ getNameCapitalized(country) }}</h2>
          <p class="paragraph-container">
            Experience the ultimate value with our exclusive special deal packages offered in {{ getNameCapitalized(country) }}. These packages combine multiple products into a single, cost-effective bundle, carefully curated to enhance your travel experience. Enjoy a convenient and budget-friendly way to savor a variety of experiences all in one, ensuring you get the most out of your journey.
          </p>
          <!-- <div
            data-tiqets-widget="discovery"
            data-cards-layout="responsive"
            data-content-type="package"
            data-item_count="6"
            data-destination-type="country"
            :data-destination-id="tiqetsId"
            data-language="en"
            data-partner="guided_valley"
            data-tq-campaign="october2023"
          /> -->
          <div data-gyg-widget="auto" />
        </div>

        <div class="paragraph-section">
          <h2>Things to know before travelling</h2>
          <div class="subcontent">
            <h3> 1. Currency</h3>
            <p class="paragraph-container">
              {{ currency }}
            </p>
            <h3>2. Plugs</h3>
            <p class="paragraph-container">
              {{ plugs }}
            </p>
            <h3>3. Emergency phone numbers</h3>
            <p class="paragraph-container">
              {{ emergency }}
            </p>
            <h3>4. Languages spoken</h3>
            <p class="paragraph-container">
              {{ languages }}
            </p>
            <h3>5. Tips</h3>
            <p class="paragraph-container">
              {{ tips }}
            </p>
            <h3>6. Best time to visit</h3>
            <p class="paragraph-container">
              {{ bestime }}
            </p>
          </div>
        </div>

        <div data-gyg-widget="auto" />
      </div>
      <div class="column-third">
        <a
          href="https://tp.media/click?shmarker=478105&promo_id=8471&source_type=banner&type=click&campaign_id=541&trs=262261"
          target="_blank"
        > <img
          src="https://c541.travelpayouts.com/content?promo_id=8471&shmarker=478105&type=init&trs=262261"
          width="320"
          height="1630"
          alt="Vertical banner displaying SIM card offers"
          loading="lazy"
        > </a> 
        <a
          href="https://tp.media/click?shmarker=478105&promo_id=3865&source_type=banner&type=click&campaign_id=130&trs=262261"
          target="_blank"
        > <img
          src="https://c130.travelpayouts.com/content?promo_id=3865&shmarker=478105&type=init&trs=262261"
          width="320"
          height="280"
          alt="Banner showing rental car offers"
          loading="lazy"
        > </a> 
        <a
          href="https://tp.media/click?shmarker=478105&promo_id=2058&source_type=banner&type=click&campaign_id=84&trs=262261"
          target="_blank"
        > <img
          src="https://c84.travelpayouts.com/content?promo_id=2058&shmarker=478105&type=init&trs=262261"
          width="320"
          height="252"
          alt="320*252"
        > </a> 
      </div>
      <div class="column-fourth" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CountryResource from '../../api/country.service'
import SearchResource from '../../api/search.service'
import CityCard from '@/components/destination/CityCard.vue'
import CityResource from '../../api/city.service'


export default {
  name: 'CountryDetails',
  components: {CityCard},
  props: {
    country: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      photoUrls: [],
      topCities: [],
      citites: [],
      description: '',
      currency: '',
      plugs: '',
      plugpicture: '',
      emergency: '',
      transport: '',
      languages: '',
      countryObj: {
      },
      photo: '',
      isoCode: '',
      tiqetsId: '',
      showTiqetsWidget: false,
    }
  },
  async created() {
    await this.fetchData()
    this.showTiqetsWidget = true
    await this.loadTiqetsScript().then(() => {
    })

    CityResource.getTopCities().then(response => {
      this.cities = response.data
    })

  },
  async beforeRouteEnter(to, from, next) {
    try {
      const response = await SearchResource.articleCheck(to.params.country)
      if (response.status === 200){
        next()
      } else {
        next({ path: '/not-found', replace: true })
      }
    } catch (error) {
      next({ path: '/not-found', replace: true })
      // next({ path: '/not-found', replace: false })
    }
  },
  async mounted() {
    /*
     * Required to initialize widgets,
     * e.g.:
     * window.eg.widgets.loaded = false
     * window.dispatchEvent(new Event('DOMContentLoaded'))
     * await this.loadScript().then(() => {})
     */
  },
  methods: {
    updateMetaData() {
      const metaDescription = document.querySelector('head meta[name="description"]')
      const metaTitle = document.querySelector('head meta[property="title"]')
      const metaKeywords = document.querySelector('head meta[name="keywords"]')
      const defaultKeywords = 'travel, adventure, journey, epic stories, tips'
      const title = document.querySelector('head title')
      const countryName = this.getNameCapitalized(this.country)
      const linkCanonical = document.querySelector('head link[rel="canonical"]')

      metaDescription.setAttribute('content', this.description)
      metaTitle.setAttribute('content', 'Guided Valley: ' + countryName)
      title.textContent = 'Guided Valley: ' + countryName
      metaKeywords.setAttribute('content', this.country + ', ' + defaultKeywords)
      linkCanonical.setAttribute('href', 'https://guidedvalley.com/countries/' + this.country.toLowerCase())
    },
    async loadTiqetsScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://widgets.tiqets.com/loader.js'

        script.onload = resolve
        script.onerror = reject

        document.head.appendChild(script)
      })
    },
    sanitizeCityQueryParam(city) {
      return city.name.toLowerCase().replaceAll(' ', '-')
    },
    mapToComponentObject(responseData) {
      const cities = []
      responseData.city.forEach(function(city) {
        cities.push({name: city.name, isTopCity: city.is_top_city, photo_urls: city.photo_urls})
      })
      console.log(responseData)
      return {
        bestTime: responseData.best_time,
        currency: responseData.currency,
        description: responseData.description,
        emergency: responseData.emergency,
        id: responseData.id,
        isTopCountry: responseData.is_top_country,
        isoCode: responseData.iso_code,
        languages: responseData.languages,
        name: responseData.name,
        photoUrls: responseData.photo_urls,
        plugs: responseData.plugs,
        tips: responseData.tips,
        transport: responseData.transport,
        tiqetsId: responseData.tiqets_id ? responseData.tiqets_id : '9999', // Use a default value if the country doesn't have a tiqets id
        cities
      }
    },
    async fetchData() {
      await CountryResource.getCountryByName(this.country.toLowerCase()).then(response => {
        this.countryObj = this.mapToComponentObject(response.data)
        this.error = this.post = null
        this.loading = true
        this.photoUrls = this.countryObj.photoUrls
        this.photo = this.countryObj.photoUrls[1]
        this.topCities = this.countryObj.cities
        this.description = this.countryObj.description
        this.transport = this.countryObj.transport    
        this.currency = this.countryObj.currency
        this.plugs = this.countryObj.plugs
        this.emergency = this.countryObj.emergency
        this.languages = this.countryObj.languages
        this.tips = this.countryObj.tips
        this.bestime = this.countryObj.bestTime
        this.isoCode = this.countryObj.isoCode
        this.tiqetsId = this.countryObj.tiqetsId

        this.updateMetaData()
      })
        .catch(e => {
          throw new Error(e)
        })
    },
    getNameCapitalized(str) {
      if (!str) {
        return ''
      } 
      // Split the string into words using dashes as the delimiter
      const words = str.split('-')

      // Capitalize the first letter of each word
      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })

      // Join the words with white spaces
      const result = capitalizedWords.join(' ')

      return result
    },
    buildAltTag() {
      return `A breathtaking view of ${this.getNameCapitalized(this.country)}`
    },
  },
}
</script>

<style lang="scss" scoped>

.container {
  max-width: 1400px;
  padding: 30px;
  text-align: left;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.photo {
  width: 100%;
  height: 85px;
  object-fit: cover;
}

.photo-paragraph {
  width: 100%;
  height: 550px;
  object-fit: cover;
  margin-top: 50px;
  margin-bottom: 50px;
}

.countryName {
  border-bottom: 1px solid #000000;
  padding: 5px;
  font-weight: 700;
  color: #324743;
  margin-left: 5px;
  margin-bottom: 10px;
  font-size: 35px;
  text-align: center;
}

.bread-crumb {
  color: #324743;
  margin-left: 5px;
  margin-bottom: 35px;
  padding: 5px;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  color: #324743;
  margin: 30px 0;
}

h3 {
  font-size: 25px;
  font-weight: 500;
  padding-top: 15px;
  color: #324743;
}

p {
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
        font-size: 18px;
  }
}

.subcontent {
  margin-top: 10px;
}

.list {
  margin-left: 30px;
}

.citieslist {
  moz-column-width: 13em;
  webkit-column-width: 11.5em;
  column-width: 13em;
}

.country-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tiqets-widget-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

.paragraph-container {
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 80em;
  margin: 30px 0;
}

.table-container {
  display: flex;
  height: 100%; /* 100% height of the viewport */
}

.column-first {
  flex: 1;
  height: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
}

.column-second {
  flex: 4; /* Occupies 2 times the space of the other columns (50%) */
  max-width: 2800px;
  padding: 0 30px 30px 30px;
  text-align: left;
}

.column-third {
  flex: 1;
  height: 100%;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
    display: none;
  }
}

.column-fourth {
  flex: 1;
  height: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
}

.paragraph-section {
  margin: 50px 0;
}
.grid {
  margin-top: 32px;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
   /* Styles for screens up to 1000px wide */
   @media (max-width: 1250px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 25px;

  }

  /* Styles for screens between 1201px and 2000px wide */
  @media (min-width: 1251px) and (max-width: 1900px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 25px;
  }
}


.item {
    display: grid;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    transition: all 300ms;
    height: 425px;
    width: 85%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: rgb(255, 255, 255);

    @media (min-width: 750px) and (max-width: 1000px) {
      width: 65%; 
      font-size: 10px !important;
  }
  }

  .city_container{
    width: 100%;

    @media (min-width: 750px) and (max-width: 1000px) {
    width: 70%; 
  }

}

</style>
