<template>
  <div class="privacy-policy-container">
    <div class="privacy-policy-content">
      <h1>Privacy Policy</h1>
      <p>Last updated: April 15, 2024</p>

      <p>
        Welcome to GuidedValley's privacy policy. Your privacy is of paramount importance to us. This privacy policy explains how we collect, use, share, and protect your personal information. By using our website, you agree to the practices outlined in this policy.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a
          href="https://www.termsfeed.com/privacy-policy-generator/"
          target="_blank"
        >Privacy Policy Profesional online agent</a>.
      </p>

      <h2>
        Interpretation
      </h2>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
      <h2>Definitions</h2>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <p><strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</p>
        </li>
        <li>
          <p><strong>Company</strong> (referred to as either "the Company," "We," "Us," or "Our" in this Agreement) refers to Guided Valley.</p>
        </li>
        <li>
          <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device, or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
        </li>
        <li>
          <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.</p>
        </li>
        <li>
          <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
        </li>
        <li>
          <p><strong>Service</strong> refers to the Website.</p>
        </li>
        <li>
          <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
        </li>
        <li>
          <p><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
        </li>
        <li>
          <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
        </li>
        <li>
          <p>
            <strong>Website</strong> refers to Guided Valley, accessible from <a
              href="https://guidedvalley.com"
              rel="external nofollow noopener"
              target="_blank"
            >https://guidedvalley.com</a>
          </p>
        </li>
        <li>
          <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
        </li>
      </ul>
      <h1>Types of Data Collected</h1>
      <h2>
        Use of Data
      </h2>
      <p>
        We utilize the data we collect to fuel our commitment to delivering exceptional content and experiences at GuidedValley. This includes personalizing your interaction with our platform, understanding your preferences, and refining our content strategy. Through careful analysis of user trends and behavior, we continuously enhance our platform to better cater to your interests.
      </p>
      <p>
        We handle your personal data with the utmost respect and discretion. Your information is not shared with third parties for their marketing purposes without your explicit consent. However, we may share aggregated, non-personally identifiable data with our partners for analytics and research purposes. For example, we might collaborate with travel organizations to showcase trends in wanderlust, sharing anonymized insights into popular destinations and emerging travel interests.
      </p>
      <p>
        Usage Data is collected automatically when you use our Service. This may include information such as the type of device you use, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data. When you access the Service via a mobile device, we may collect certain information automatically, including the type of mobile device you use, your mobile device unique ID, the type of mobile Internet browser you use, and other diagnostic data. We may also collect information that your browser sends whenever you visit our Service or access it through a mobile device.
      </p>
      <h3>Information from Third-Party Social Media Services</h3>
      <p>The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:</p>
      <ul>
        <li>Google</li>
        <li>Facebook</li>
        <li>Instagram</li>
        <li>Twitter</li>
        <li>LinkedIn</li>
      </ul>
      <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.</p>
      <p>You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.</p>
      <h3>Tracking Technologies and Cookies</h3>
      <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
      <ul>
        <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
        <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
      </ul>
      <p>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies on <a
          href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
          target="_blank"
        >TermsFeed website</a> article.
      </p>
      <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
      <ul>
        <li>
          <p><strong>Necessary / Essential Cookies</strong></p>
          <p>Type: Session Cookies</p>
          <p>Administered by: Us</p>
          <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
        </li>
        <li>
          <p><strong>Analytics / Notice Acceptance Cookies</strong></p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
        </li>
      </ul>
      <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
      <h2>Use of Your Personal Data</h2>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li><strong>To provide and maintain our Service,</strong> including to monitor the usage of our Service.</li>
        <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
        <li><strong>For the performance of a contract:</strong> the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.</li>
        <li><strong>To provide You</strong> with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
        <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
      </ul>

      <!-- Data Collection Section -->
      <h2>
        Data Collection
      </h2>
      <p>
        As you navigate GuidedValley's enriching content, we may automatically collect certain information from you. This includes your IP address, browser type, device information, and browsing patterns. Rest assured, this data assists us in enhancing your user experience, optimizing our content, and improving our website's functionality.
      </p>
      <p>
        Additionally, we utilize cookies and similar technologies to gather data about your interactions with our website. Cookies are small text files that are stored on your device and enable us to recognize you on subsequent visits. These cookies help us personalize your experience and provide you with tailored content.
      </p>
      <p>
        For example, if you're an avid reader of our adventure stories, cookies allow us to remember your preferences and display related content on your next visit.
      </p>
  
      <!-- Cookies Section -->
      <h2>
        Cookies
      </h2>
      <p>
        Cookies are integral to providing you with a seamless browsing experience. These tiny data files store information about your preferences and interactions with our website. By harnessing the power of cookies, we can tailor our content to suit your individual preferences and deliver relevant recommendations.
      </p>
      <p>
        Our trusted partners, such as Freestar and Google, may also employ cookies for advertising purposes. These cookies aid in serving you ads that align with your interests and online behavior. For example, if you frequently read about beach destinations, you might come across travel-related ads promoting exotic coastal getaways.
      </p>
      <strong><p> If you would like to reset your cookies consent, please click on the floating button on the bottom left side of the website.</p></strong>
      
      <!-- Third-Party Partners Section -->
      <h2>
        Third-Party Partners
      </h2>
      <p>
        GuidedValley collaborates with esteemed partners to offer you an enriched experience. Our partnerships encompass a spectrum of services, from content delivery to analytics. It's important to note that these partners operate under their own privacy policies, which may differ from ours.
      </p>
      <p>
        For example, our advertising partners might utilize data to deliver ads that align with your interests. This collaboration ensures you receive relevant advertisements while allowing us to focus on curating captivating content.
      </p>
      <!-- Changes to Policy Section -->
      <h2>
        Changes to Policy
      </h2>
      <p>
        We reserve the right to update this privacy policy periodically to reflect changes in our practices and to stay in alignment with regulatory requirements. When we make modifications, we will post the revised policy on this page, along with a notification of the changes. We encourage you to revisit this policy regularly to stay informed about how we safeguard your information.
      </p>
      <p>
        For instance, if there's a change in data collection methods or how we collaborate with partners, we'll ensure that these updates are communicated transparently through this policy.
      </p>
  
      <!-- Conclusion Section -->
      <h2>
        Conclusion
      </h2>
      <p>
        Thank you for entrusting us with your privacy as you journey through GuidedValley. Beyond being a blog, GuidedValley is a community of explorers and storytellers. Our tales aim to spark your wanderlust and inspire your own expeditions. Together, we celebrate the magnificence of our world and the connections that unite us all.
      </p>
      <p>
        Should you have any questions, concerns, or suggestions regarding our privacy practices or any aspect of GuidedValley, please feel free to contact us. We value your input and look forward to sharing many more incredible stories and experiences with you.
      </p>
      <p>
        With boundless gratitude and the spirit of adventure,
        <br><br>
        Lulu and Denise
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'PrivacyPolicy',
  created() {
    this.resetMetaData()
  },
  methods: {
    resetMetaData() {
      const metaDescription = document.querySelector('head meta[name="description"]')
      const metaTitle = document.querySelector('head meta[property="title"]')
      const metaKeywords = document.querySelector('head meta[name="keywords"]')
      const defaultKeywords = 'travel, adventure, journey, epic stories, tips'
      const title = document.querySelector('head title')
      const linkCanonical = document.querySelector('head link[rel="canonical"]')

      metaDescription.setAttribute('content', 'Join our journey! Discover epic travel stories and expert tips to conquer the world. Let\'s make your adventures unforgettable. Explore with us today!')
      metaTitle.setAttribute('content', 'Guided Valley: Privacy Policy')
      title.textContent = 'Guided Valley: Privacy Policy'
      metaKeywords.setAttribute('content', defaultKeywords)
      linkCanonical.setAttribute('href', 'https://guidedvalley.com/privacy')
    }
  }
}
</script>

<style scoped>
  /* Styles for the privacy policy page */

  .privacy-policy-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .privacy-policy-content {
    max-width: 1400px;
    padding: 70px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  button {
    padding: 12px 32px;
    border: 1px solid #333;
    background-color: white;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  h1 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  h2 {
    font-size: 22px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  ul {
    padding: 20px;
    margin-left: 10px;
  }

  li {
    font-size: 20px;
  }
</style>
  
