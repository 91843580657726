<template>
  <div class="article-details-container">
    <div class="table-container">
      <div class="column-first">
        <div
          class="banner-container"
        >
          <!-- test -->
        </div>
      </div>

      <div class="column-second">
        <div>
          <div class="bread-crumb">
            <router-link
              :to="{ name: 'Home'}"
            >
              Home
            </router-link>
            >
            <router-link
              :to="{ name: 'CountryDetails', params: { country: country.toLocaleLowerCase().replace(/\s+/g, '-') } }"
            >
              {{ getNameCapitalized(country) }}
            </router-link>
            >
            <router-link
              :to="{ name: 'CityDetails', params: { country: country.toLocaleLowerCase().replace(/\s+/g, '-'), city: city.toLocaleLowerCase().replace(/\s+/g, '-') } }"
            >
              {{ getNameCapitalized(city) }}
            </router-link>
            >
            <span> 
              {{ getNameCapitalized(path) }} 
            </span>
          </div>
          <h1 class="title">
            {{ article.title.toUpperCase() }}
          </h1>  

          <div
            v-for="content in article.introduction.content"
            :key="content"
          >
            <p class="paragraph-container">
              {{ content }}
            </p>
          </div>

          <div
            v-if="isDataReady"
            class="tiqets-widget-container"
          >
            <h2 class="pharagraph-title-first">
              Top activities we recommend in {{ getNameCapitalized(city) }}:
            </h2>
            <!-- change destination id and use dynamic one -->
            <!-- <div
              data-tiqets-widget="discovery"
              data-cards-layout="responsive"
              data-content-type="product"
              data-item_count="5"
              data-destination-type="city"
              :data-destination-id="tiqetsId" 
              data-language="en"
              data-partner="guided_valley"
              data-tq-campaign="october2023"
            /> -->
            <div data-gyg-widget="auto" />
          </div>

          <img
            class="photo-paragraph"
            :src="photoUrls[2]"
            loading="lazy"
            :alt="buildAltTag()"
          >

          <div class="paragraph-section">
            <h2> Find acommodations in {{ getNameCapitalized(city) }}</h2>
            <iframe
              id="stay22-widget"
              width="100%"
              height="428"
              :src="'https://www.stay22.com/embed/gm?aid=guidedvalley&'+coordinates.fullCoordinates+'&disablerentals=true&zoom=14'"
              frameborder="0"
              :title="'Map of '+getNameCapitalized(city)+' displaying accommodations available'"
            />
          </div>
          

          <!-- Open Weather Map -->
          <div
            v-if="isDataReady"
            class="weather-widget-container"
          >
            <h2>
              7-Day {{ getNameCapitalized(city) }} Weather Forecast
            </h2>
            <VueWeatherWidget
              class="weather-widget"
              api-key="xxx"
              units="uk"
              :latitude="coordinates.lat"
              :longitude="coordinates.long"
              bar-color="#505050"
              hide-header="true"
            />
          </div>

          <div>
            <div
              v-for="(section, index) in article.sections"
              :key="section.title.toLowerCase().replace(/\s/g, '') + '-' + index"
            >
              <div class="paragraph-section">
                <h2 class="pharagraph-title">
                  {{ index + 1 }}. {{ section.title }}
                </h2>
                <div
                  v-for="content in section.content"
                  :key="content.subtitle"
                >
                  <h3 class="pharagraph-title">
                    {{ content.subtitle }}
                  </h3>

                  <div
                    v-for="detail in content.details"
                    :key="detail"
                  >
                    <p class="paragraph-container">
                      {{ detail }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="isDataReady"
              class="tiqets-widget-container"
            >
              <h2 class="pharagraph-title">
                Check Exhibitions that are open right now in {{ getNameCapitalized(city) }}
              </h2>
              <!-- change destination id and use dynamic one -->
              <!-- <div
                data-tiqets-widget="discovery"
                data-cards-layout="responsive"
                data-content-type="exhibition"
                data-item_count="7"
                data-destination-type="city"
                :data-destination-id="tiqetsId" 
                data-language="en"
                data-partner="guided_valley"
                data-tq-campaign="october2023"
              /> -->
              <div data-gyg-widget="auto" />
            </div>

            <div class="conclusion">
              <h2 class="pharagraph-title">
                Conclusion
              </h2>  

              <div
                v-for="content in article.conclusion.content"
                :key="content"
              >
                <p
                  class="paragraph-container"
                >
                  {{ content }}
                </p>
              </div>
            </div>

            <div
              v-if="isDataReady"
              class="tiqets-widget-container"
            >
              <h2 class="pharagraph-title">
                Top Attractions in {{ getNameCapitalized(city) }}
              </h2>
              <!-- change destination id and use dynamic one -->
              <!-- <div
                data-tiqets-widget="discovery"
                data-cards-layout="responsive"
                data-content-type="venue"
                data-item_count="5"
                data-destination-type="city"
                :data-destination-id="tiqetsId" 
                data-language="en"
                data-partner="guided_valley"
                data-tq-campaign="october2023"
              /> -->
              <div data-gyg-widget="auto" />
            </div>
          </div> 
        </div>
      </div>

      <div class="column-third">
        <a
          href="https://tp.media/click?shmarker=478105&promo_id=8471&source_type=banner&type=click&campaign_id=541&trs=262261"
          target="_blank"
        > <img
          src="https://c541.travelpayouts.com/content?promo_id=8471&shmarker=478105&type=init&trs=262261"
          width="320"
          height="1630"
          alt="Vertical banner displaying SIM card offers"
          loading="lazy"
        > </a> 
        <a
          href="https://tp.media/click?shmarker=478105&promo_id=3865&source_type=banner&type=click&campaign_id=130&trs=262261"
          target="_blank"
        > <img
          src="https://c130.travelpayouts.com/content?promo_id=3865&shmarker=478105&type=init&trs=262261"
          width="320"
          height="280"
          alt="Banner showing rental car offers"
          loading="lazy"
        > </a> 
        <a
          href="https://tp.media/click?shmarker=478105&promo_id=2058&source_type=banner&type=click&campaign_id=84&trs=262261"
          target="_blank"
        > <img
          src="https://c84.travelpayouts.com/content?promo_id=2058&shmarker=478105&type=init&trs=262261"
          width="320"
          height="252"
          alt="320*252"
        > </a> 
      </div>
      <div class="column-fourth">
        <div
          class="banner-container"
        >
          <!-- test -->
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ArticleResource from '../../api/article.service'
import VueWeatherWidget from '../../components/weather/VueWeatherWidget.vue'
import SearchResource from '../../api/search.service'
  
  
export default {
  name: 'ArticleDetails',
  components: {VueWeatherWidget},
  props: {
  },
  data() {
    return {
      photoUrls: [],
      article: {
        title: '',
        meta_description: '',
        keywords: '',
        slug: '',
        excerpt: '',
        conclusion: {
          content: [],
        },
        introduction: {
          content: [],
        },
        sections: [
          {
            title: '',
            content: [
              {
                subtitle: '',
                details: []
              }
            ]
          }
        ],
        faqs: {
          content: [
            {
              question: '',
              answer: ''
            }
          ]
        }
      },
      cityId: null,
      countryId: null,
      title: '',
      path: '',
      id: null,
      isDataReady: false,
      tiqetsId: '',
      city: '',
      country: '',
      coordinates: {
        fullCoordinates: '',
        lat: Number,
        long: Number,
      },
    }
  },
  async created() {
    await this.fetchData()
    this.isDataReady = true
    await this.loadTiqetsScript().then(() => {})
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const response = await SearchResource.articleCheck(to.params.country, to.params.city, to.params.article)
      if (response.status === 200){
        next()
      } else {
        next({ path: '/not-found', replace: true })
      }
    } catch (error) {
      console.error('Error checking article:', error)
      next({ path: '/not-found', replace: true })
    }

  },
  methods: {  
    async fetchData() {
      this.city = this.$route.params.city
      this.country = this.$route.params.country
      this.path = this.$route.params.article

      await ArticleResource.getArticleByPath(this.path).then(response => {
        this.mapToComponentObject(response.data)
        this.updateMetaData()
      })
        .catch(e => {
          throw new Error(e)
        })
    },
    
    loadTiqetsScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://widgets.tiqets.com/loader.js'

        script.onload = resolve
        script.onerror = reject

        document.head.appendChild(script)
      })
    },
    getNameCapitalized(str) {
      if (!str) {
        return ''
      } 
      // Split the string into words using dashes as the delimiter
      const words = str.split('-')

      // Capitalize the first letter of each word
      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })

      // Join the words with white spaces
      const result = capitalizedWords.join(' ')

      return result
    },
    mapToComponentObject(responseData) {
      this.description = responseData.description
      this.title = responseData.title
      this.countryId = responseData.country_id
      this.cityId = responseData.city_id
      this.id = responseData.id
      this.photoUrls = responseData.photo_urls
      this.article = responseData.content
      this.tiqetsId = responseData.tiqets_id ? responseData.tiqets_id : '9999' // Use a default value if the country doesn't have a tiqets id
      this.coordinates = this.extractCoordinates(responseData.coordinates)
      this.keywords = responseData.keywords
    },
    extractCoordinates(rawCoordinates) {
      const params = new URLSearchParams(rawCoordinates)
      const lat = parseFloat(params.get('lat'))
      const long = parseFloat(params.get('lng'))

      return {
        fullCoordinates: rawCoordinates,
        lat,
        long,
      }
    },
    updateMetaData() {
      const metaDescription = document.querySelector('head meta[name="description"]')
      const metaTitle = document.querySelector('head meta[property="title"]')
      const metaKeywords = document.querySelector('head meta[name="keywords"]')
      const title = document.querySelector('head title')
      const linkCanonical = document.querySelector('head link[rel="canonical"]')

      metaDescription.setAttribute('content', this.article.meta_description)
      metaTitle.setAttribute('content', this.article.title)
      title.textContent = this.article.title
      metaKeywords.setAttribute('content', this.article.keywords)
      linkCanonical.setAttribute('href', 'https://guidedvalley.com/countries/' + this.country + '/cities/' + this.city + '/articles/' + this.path)
    },
    buildAltTag() {
      return `A breathtaking view of ${this.getNameCapitalized(this.city)}`
    },
  },
}
</script>
  
<style lang="scss" scoped>
.container {
  max-width: 1400px;
  padding: 30px;
  text-align: left;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.photo {
  width: 100%;
  height: 85px;
  object-fit: cover;
}

.photo-paragraph {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 50px;
  margin-bottom: 50px;
}

.bread-crumb {
  color: #324743;
  margin-left: 5px;
  margin-bottom: 35px;
  padding: 5px;
}

h1{
  padding: 5px 0;
  margin-left: 5px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #324743;
}

h2{
  color: #324743;
  font-size: 30px;
  font-weight: 600;
  margin: 20px 0;
}
.title{
  border-bottom: 1px solid #000000;
  font-weight: 700;
  margin-left: 5px;
  font-size: 35px;
  text-align: center;
}

.pharagraph-title {
  font-weight: 600;
  font-size: 30px;
  margin: 0.7em 0px 0.2em 0px;
}

.pharagraph-title-first {
  font-weight: 600;
  font-size: 30px;
}

p {
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
  margin: 20px 0;
}

.conclusion {
  margin: 50px 0;
}

.article-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.article-details-content {
  max-width: 1400px;
  padding: 15px;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.headout-widget-container {
  margin-left: auto;
  margin-right: auto;
}

.tiqets-widget-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

.paragraph-container {
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 80em;
}

.table-container {
  display: flex;
  height: 100%; /* 100% height of the viewport */
}
.column-first {
  flex: 1;
  height: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
}
.column-fourth {
  flex: 1;
  height: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
}
.column-second {
  flex: 4; /* Occupies 2 times the space of the other columns (50%) */
  max-width: 2800px;
  padding: 0 30px 30px 30px;
  text-align: left;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.column-third {
  flex: 1;
  height: 100%;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
    display: none;
  }
}

.weather-widget {
  margin-left: auto;
  margin-right: auto;
}

.weather-widget-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.paragraph-section {
  margin: 50px 0;
}

.container-image {
  position: relative;
  text-align: center;
  color: white;
}
</style>

