<template>
  <div>
    <h1>404 - Page Not Found</h1>
    <h2>Sorry, the page you are looking for does not exist.</h2>
    <router-link to="/">Go back to Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  created() {
    window.location.href = '/404.html'
  }
}
</script>

<style lang="scss" scoped>
/* Your styles here */
</style>
