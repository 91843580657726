import HTTP from './http-common'

/*
 * Service should be singleton,
 * hence we could declare a simple object literal.
 */
let ArticleResource = {
  getArticleByPath(articlePath) {
    return HTTP.get('/articles/' + articlePath )
  },
}

export default ArticleResource
