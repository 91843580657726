import HTTP from './http-common'

/*
 * Service should be singleton,
 * hence we could declare a simple object literal.
 */
let SearchResource = {
  search(query) { 
    return HTTP.get('/search?q=' + query)
  },

  articleCheck(country, city, articlePath) {
    return HTTP.get('/articlesCheck', {
      params: {
        country,
        city,
        articlePath
      }
    })
  }

}

export default SearchResource
