<template>
  <footer class="footer-distributed">
    <div class="footer-left">
      <a
        href="/"
        class="logo-container"
      >
        <img
          class="logo-img"
          :src="logo"
          loading="lazy"
          alt="Logo of Guided Valley"
        >
      </a>

      <p class="footer-links">
        <router-link class="footer-links-elem" to="/">
          Home
        </router-link>
        <router-link class="footer-links-elem" to="/about">
          About
        </router-link>
        <router-link class="footer-links-elem" to="/privacy">
          Privacy
        </router-link>
        <a class="footer-links-elem" href="mailto:guidedvalley@gmail.com">Contact</a>
      </p>

      <p class="footer-company-name">
        © 2024 GuidedValley. All Rights Reserved.
      </p>
    </div>

    <div class="footer-center">
      <!-- <div>
        <i class="fa fa-map-marker" />
        <p><span>The world is yours, explore it!</span></p>
      </div> -->
    </div>

    <div class="footer-right">
      <p class="footer-company-about">
        <span>About the company</span>
        Join our journey! Join our trip! Discover the best tips and what you can't miss on your next trip. Let us make your adventures unforgettable - explore with us today!
      </p>

      <!-- <div class="footer-icons">
        <a href="#"><i class="fa fa-facebook" /></a>
        <a href="#"><i class="fa fa-twitter" /></a>
        <a href="#"><i class="fa fa-linkedin" /></a>
        <a href="#"><i class="fa fa-github" /></a>
      </div> -->
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      logo: require('@/assets/logo.png') // Path to your SVG logo
    }
  },
}
</script>

<style lang="scss" scoped>

.logo-container {
    align-self: center;
}

.logo-img {
  height: 55px;
}


section {
  width: 100%;
  display: inline-block;
  background: #333;
  height: 50vh;
  text-align: center;
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
  font-weight: 600;
  text-decoration: underline;
}

.footer-distributed{
  background: #000000;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
  width: 40%;
}

/* The company logo */

.footer-distributed h3{
  color:  #ffffff;
  font: normal 36px, cursive;
  margin: 0;
}

.footer-distributed h3 span{
  color:  lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links{
  color:  #ffffff;
  margin: 20px 0 12px;
  padding: 0;
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
}

.footer-distributed .footer-links a{
  display:inline-block;
  line-height: 1.8;
  font-weight:500;
  text-decoration: none;
  color:  inherit;
}

.footer-distributed .footer-company-name{
  color:  #ffffff;
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
  font-weight: 500;
  margin: 0;
  margin-top: 24px;
}

/* Footer Center */

.footer-distributed .footer-center{
  width: 35%;
}

.footer-distributed .footer-center i{
  background-color:  #33383b;
  color: #ffffff;
  font-size: 25px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 20px;
  }
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
  font-size: 22px;
  line-height: 38px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
}

.footer-distributed .footer-center p{
  display: inline-block;
  color: #ffffff;
  font-weight:500;
  vertical-align: middle;
  margin:0;
}

.footer-distributed .footer-center p span{
  display:block;
  font-weight: 500;
  font-size:22px;
  line-height:2;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
}

.footer-distributed .footer-center p a{
  color:  lightseagreen;
  text-decoration: none;;
}

.footer-distributed .footer-links a:before {
  font-weight:500;
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
  width: 20%;
}

.footer-distributed .footer-company-about{
  //line-height: 20px;
  color:  #92999f;
  font-size: 20px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
  font-weight: 500;
  margin: 0;
}

.footer-distributed .footer-company-about span{
  display: block;
  color:  #ffffff;
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
  font-weight: 700;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons{
  margin-top: 25px;
}

.footer-distributed .footer-icons a{
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color:  #33383b;
  border-radius: 2px;
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
      font-size: 18px;
  }
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i{
    margin-left: 0;
  }
}

.footer-links-elem {
  margin-right: 10px;
}
</style>
