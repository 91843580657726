<template>
  <div class="city-details-container">
    <div class="table-container">
      <div class="column-first" />
      <div class="column-second">
        <div>
          <div class="bread-crumb">
            <router-link
              :to="{ name: 'Home'}"
            >
              Home
            </router-link>
            >
            <router-link
              :to="{ name: 'CountryDetails', params: { country: country.toLocaleLowerCase().replace(/\s+/g, '-') } }"
            >
              {{ getNameCapitalized(country) }}
            </router-link>
            >
            <span> 
              {{ getNameCapitalized(city) }} 
            </span>
          </div>
            
          <h1 class="cityName">
            {{ getNameCapitalized(city).toUpperCase() }} TRAVEL GUIDE
          </h1>

          <p class="paragraph-container">
            {{ description }}
          </p>

          

          <div
            v-if="isDataReady"
            class="tiqets-widget-container"
          >
            <h2>
              Top activities we recommend in {{ getNameCapitalized(city) }}
            </h2>
            <!-- <div
              data-tiqets-widget="discovery"
              data-cards-layout="responsive"
              data-content-type="product"
              data-item_count="5"
              data-destination-type="city"
              :data-destination-id="tiqetsId"
              data-language="en"
              data-partner="guided_valley"
              data-tq-campaign="october2023"
            /> -->
            <div data-gyg-widget="auto" />
          </div>

          <img
            class="photo-paragraph"
            :src="photoUrls[1]"
            loading="lazy"
            :alt="buildAltTag()"
          >

          <div v-if="articles && articles.length > 0">
            <h2>
              Best articles about {{ getNameCapitalized(city) }}
            </h2>
            <div 
              v-for="article in articles"
              :key="article.id"
              class="articles"
            >
              <div>
                <router-link
                
                  :to="{
                    name: 'ArticleDetails',
                    params: { country: country, city: city, article: article.path },
                  }"
                >
                  {{ article.title }}
                </router-link>
              </div>
            </div>
          </div>

          <!-- Open Weather Map -->
          <div
            v-if="isDataReady"
            class="weather-widget-container"
          >
            <h2>
              7-Day {{ getNameCapitalized(city) }} Weather Forecast
            </h2>
            <VueWeatherWidget
              class="weather-widget"
              api-key="xxx"
              units="uk"
              :latitude="coordinates.lat"
              :longitude="coordinates.long"
              bar-color="#505050"
              hide-header="true"
            />
          </div>


          <div class="paragraph-section">
            <h2> Public transport</h2>
            <p class="paragraph-container">
              {{ transport }}
            </p>
          </div>
            
          <div class="paragraph-section">
            <h2> Find acommodations in {{ getNameCapitalized(city) }}</h2>
            <iframe
              id="stay22-widget"
              width="100%"
              height="428"
              :src="'https://www.stay22.com/embed/gm?aid=guidedvalley&'+coordinates.fullCoordinates+'&disablerentals=true&zoom=14'"
              frameborder="0"
              :title="'Map of '+getNameCapitalized(city)+' displaying accommodations available'"
            />
          </div>
            

          <div class="paragraph-section">
            <h2> Food you must try</h2>
            <p class="paragraph-container">
              {{ food }}
            </p>
          </div>
            
        
          <div
            v-if="isDataReady"
            class="tiqets-widget-container"
          >
            <h2> Exhibitions</h2>
            <p class="paragraph-container">
              Are you seeking a diverse array of exciting activities and the latest cultural events happening right now in {{ getNameCapitalized(city) }}? Explore a vibrant tapestry of exhibitions in {{ getNameCapitalized(city) }}, where the city's rich history and contemporary art scene intertwine to offer a captivating experience for art enthusiasts, tourists, and culture aficionados alike.
            </p>
            <!-- <div
              data-tiqets-widget="discovery"
              data-cards-layout="responsive"
              data-content-type="exhibition"
              data-item_count="7"
              data-destination-type="city"
              :data-destination-id="tiqetsId"
              data-language="en"
              data-partner="guided_valley"
              data-tq-campaign="october2023"
            /> -->
            <div data-gyg-widget="auto" />
          </div>


          <div class="paragraph-section">
            <h2>Things to know before travelling</h2>
            <div class="subcontent">
              <h3>1. Currency</h3>
              <p class="paragraph-container">
                {{ currency }}
              </p>
              <h3>2. Plugs</h3>
              <p class="paragraph-container">
                {{ plugs }}
              </p>

              <h3>3. Emergency phone numbers</h3>
              <p class="paragraph-container">
                {{ emergency }}
              </p>
              <h3>4. Scams</h3>
              <p class="paragraph-container">
                {{ scams }}
              </p>

              <h3>5. Languages spoken</h3>
              <p class="paragraph-container">
                {{ languages }}
              </p>
              <h3>6. WIFI</h3>
              <p class="paragraph-container">
                {{ wifi }}
              </p>
              <h3>7. Best time to visit</h3>
              <p class="paragraph-container">
                {{ bestime }}
              </p>
              <h3>8. Tips</h3>
              <p class="paragraph-container">
                {{ tips }}
              </p>
            </div>
          </div>

          <div data-gyg-widget="auto" />
        </div>
      </div>
      <div class="column-third">
        <a
          href="https://tp.media/click?shmarker=478105&promo_id=8471&source_type=banner&type=click&campaign_id=541&trs=262261"
          target="_blank"
        > <img
          src="https://c541.travelpayouts.com/content?promo_id=8471&shmarker=478105&type=init&trs=262261"
          width="320"
          height="1630"
          alt="Vertical banner displaying SIM card offers"
          loading="lazy"
        > </a> 
        <a
          href="https://tp.media/click?shmarker=478105&promo_id=3865&source_type=banner&type=click&campaign_id=130&trs=262261"
          target="_blank"
        > <img
          src="https://c130.travelpayouts.com/content?promo_id=3865&shmarker=478105&type=init&trs=262261"
          width="320"
          height="280"
          alt="Banner showing rental car offers"
          loading="lazy"
        > </a> 

        <a
          href="https://tp.media/click?shmarker=478105&promo_id=2058&source_type=banner&type=click&campaign_id=84&trs=262261"
          target="_blank"
        > <img
          src="https://c84.travelpayouts.com/content?promo_id=2058&shmarker=478105&type=init&trs=262261"
          width="320"
          height="252"
          alt="320*252"
        > </a> 
      </div>
      <div class="column-fourth" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CityResource from '../../api/city.service'
import VueWeatherWidget from '../../components/weather/VueWeatherWidget.vue'
import SearchResource from '../../api/search.service'

export default {
  name: 'CityDetails',
  components: {
    VueWeatherWidget
  },
  data() {
    return {
      photoUrls: [],
      description: '',
      transport: '',
      food: '',
      currency: '',
      plugs: '',
      tips: '',
      emergency: '',
      scams: '',
      languages: '',
      wifi: '',
      atm: '',
      bestime: '',
      isTopCity: false,
      city: '',
      country: '',
      cityId: 0,
      isDataReady: false,
      tiqetsId: '',
      coordinates: {
        fullCoordinates: '',
        lat: Number,
        long: Number,
      },
    }
  },
  async created() {
    await this.fetchData()
    this.isDataReady = true
    await this.loadTiqetsScript().then(() => {
    })
  },
  async beforeRouteEnter(to, from, next) {
    try{
      const response = await SearchResource.articleCheck(to.params.country, to.params.city)
      if (response.status === 200){
        next()
      } else {
        next({ path: '/not-found', replace: true })
      }
    } catch (error) {
      console.error('Error checking article:', error)
      next({ path: '/not-found', replace: true })
    }
  },
  async mounted() {
    /*
     * Required to initialize widgets
     * window.eg.widgets.loaded = false
     * window.dispatchEvent(new Event('DOMContentLoaded'))
     */
  },
  methods: {
    forwardToCountryDetails() {
      this.$router.push({
        name: 'CountryDetails',
        params: { country: this.country.toLocaleLowerCase().replace(/\s+/g, '-') },
      })
    },
    mapToComponentObject(responseData) {
      this.description = responseData.description
      this.transport = responseData.transport
      this.food = responseData.food 
      this.currency = responseData.currency
      this.bestime = responseData.best_time
      this.cityId = responseData.id
      this.isTopCity = responseData.is_top_city

      this.plugs = responseData.plugs
      this.emergency = responseData.emergency

      this.scams = responseData.scams 
      this.languages = responseData.languages
      this.wifi = responseData.wifi
      this.atm = responseData.atm
      this.tips = responseData.tips 
      this.photoUrls = responseData.photo_urls
      this.articles = responseData.articles
      this.tiqetsId = responseData.tiqets_id ? responseData.tiqets_id : '9999' // Use a default value if the country doesn't have a tiqets id
      this.coordinates = this.extractCoordinates(responseData.coordinates)
    },

    extractCoordinates(rawCoordinates) {
      const params = new URLSearchParams(rawCoordinates)
      const lat = parseFloat(params.get('lat'))
      const long = parseFloat(params.get('lng'))

      return {
        fullCoordinates: rawCoordinates,
        lat,
        long,
      }
    },

    async fetchData() {
      this.country = this.$route.params.country
      this.city = this.$route.params.city

      await CityResource.getCityByCountryAndCity(this.country, this.city).then(response => {
        this.mapToComponentObject(response.data)
        this.updateMetaData()
      })
        .catch(e => {
          throw new Error (e)
        })
    },
    updateMetaData() {
      const metaDescription = document.querySelector('head meta[name="description"]')
      const metaTitle = document.querySelector('head meta[property="title"]')
      const metaKeywords = document.querySelector('head meta[name="keywords"]')
      const defaultKeywords = 'travel, adventure, journey, epic stories, tips'
      const title = document.querySelector('head title')
      const cityName = this.getNameCapitalized(this.city)
      const linkCanonical = document.querySelector('head link[rel="canonical"]')

      metaDescription.setAttribute('content', this.description)
      metaTitle.setAttribute('content', 'Guided Valley: ' + cityName)
      title.textContent = 'Guided Valley: ' + cityName
      metaKeywords.setAttribute('content', this.city + ', ' + defaultKeywords)
      linkCanonical.setAttribute('href', 'https://guidedvalley.com/countries/' + this.country + '/cities/' + this.city)

    },
    getNameCapitalized(str) {
      if (!str) {
        return ''
      } 
      // Split the string into words using dashes as the delimiter
      const words = str.split('-')

      // Capitalize the first letter of each word
      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })

      // Join the words with white spaces
      const result = capitalizedWords.join(' ')

      return result
    },
    forwardToArticleDetails(articlePath) {
      this.$router.push({
        name: 'ArticleDetails',
        params: { country: this.$route.params.country, city: this.$route.params.city, article: articlePath},
      })
    },
    buildAltTag() {
      return `A breathtaking view of ${this.city}`
    },
    loadTiqetsScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://widgets.tiqets.com/loader.js'

        script.onload = resolve
        script.onerror = reject

        document.head.appendChild(script)
      })
    }
  },
}
</script>

<style lang="scss" scoped>

.container {
  max-width: 1400px;
  padding: 30px;
  text-align: left;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.photo {
  width: 100%;
  height: 85px;
  object-fit: cover;
}

.photo-paragraph {
  width: 100%;
  height: 550px;
  object-fit: cover;
  margin-top: 50px;
  margin-bottom: 50px;
}

.bread-crumb {
  color: #324743;
  margin-left: 5px;
  margin-bottom: 35px;
  padding: 5px;
}

.cityName {
  border-bottom: 1px solid #000000;
  padding: 5px;
  font-weight: 700;
  color: #324743;
  margin-left: 5px;
  margin-bottom: 10px;
  font-size: 35px;
  text-align: center;
}

.articles{
  padding: 10px;
  margin-left: 11px;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  color: #324743;
  margin: 20px 0;
}

h3 {
  font-size: 25px;
  font-weight: 500;
  padding-top: 15px;
  color: #324743;
}

h4 {
  font-size: 20px;
  font-weight: 500;
  padding-left: 50px;
}

p {
  font-size: 22px;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
        font-size: 18px;
  }
}

.subcontent {
  margin-top: 10px;
}

table {
  width: 100%;
  table-layout: fixed;
}

.tbl-header {
  background-color: #d7d7d7;
}

.tbl-content {
  height: auto;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid #d7d7d7;
}

th {
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 22px;
  text-transform: uppercase;
}

td {
  padding: 20px;
  text-align: left;
  vertical-align: middle;
  font-weight: 500;
  font-size: 2px;
  border-bottom: solid 1px #d7d7d7;

}

.city-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.city-details-content {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.headout-widget-container {
  margin-left: auto;
  margin-right: auto;
}

.tiqets-widget-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

.paragraph-container {
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 80em;
  margin: 20px 0;
}

.table-container {
  display: flex;
  height: 100%; /* 100% height of the viewport */
}
.column-first {
  flex: 1;
  height: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
}

.column-second {
  flex: 4; /* Occupies 2 times the space of the other columns (50%) */
  max-width: 2800px;
  padding: 0 30px 30px 30px;
  text-align: left;
}
.column-third {
  flex: 1;
  height: 100%;
  /* Styles for screens up to 1000px wide */
  @media (max-width: 1000px) {
    display: none;
  }
}

.column-fourth {
  flex: 1;
  height: 100%;
  @media (max-width: 1000px) {
    display: none;
  }
}

.weather-widget {
  margin-left: auto;
  margin-right: auto;
}

.weather-widget-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.paragraph-section {
  margin: 50px 0;
}

.container-image {
  position: relative;
  text-align: center;
  color: white;
}
</style>
